body {
  background-color: #1A1B21;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.App {
  display: flex;
  justify-content: center;
}

.App--main {
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 5vh;
  margin-top: 5vh;
  background-color: #282c34;
  height: auto;
  min-width: 60px;
  width: 80vw;
  max-width: 500px;
}

.placeholder {
  height: max-content;
}

.App--my-photo {
  min-width: 60px;
  width: 80vw;
  max-width: 500px;
}

.App--my-photo img {
  border-radius: 10px 10px 0 0;

  min-width: 60px;
  width: 80vw;
  max-width: 500px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Experiences--title{

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
