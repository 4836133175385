.footer--social-media {
    background: #161619;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;

    min-height: 5vh;
    display: flex;
    align-content: center;
    justify-content: center;
}

.footer--social-media > a {
    border: 0;
    padding: 1vh;
}