.Basics {
    height: auto;
    width: auto;
}

.Basics--FullName {
    margin: 1.5vw 0 0 0;
    justify-content: center;

    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: calc(min(15px + 2vw, 40px));
    text-align: center;

    color: #FFFFFF;
}

.Basics--Title {
    margin: 0.5vh 0 2vh 0;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: calc(min(10px + 1vw, 20px));
    text-align: center;

    color: #F3BF99;
}

.Basics--Buttons {
    display: flex;
    margin: 1vw auto 20px auto;
    justify-content: center;
}

.Basics--Button  img {
    margin: auto min(0.5vw, 2px);
    width: 20px;
    fill: gray;
}

.Basics--Button p {
    padding: 0;
    margin: auto min(0.5vw, 2px);
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    font-size: calc(min(10px + 1vw, 15px));
    line-height: 1vh;
    text-align: center;
    color: #1E1F25;
}

.Basics--Button {
    padding: 2px;
    margin: 0 10px;
    text-align: center;
    justify-content: center;
    align-content: center;
    display: flex;
    max-width: 200px;
    min-width: 50px;
    min-height: 40px;
    width: 25vw;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.Basics--LinkedinButton {
    background: #5093E2;
    border: 1px solid #5093E2;
}

.Basics--LinkedinButton > p {
    color: white;
    font-weight: 800;
}